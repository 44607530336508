import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Navbar from './components/Navbar'; 
import HamburgerMenu from './components/HamburgerMenu'; 
import Footer from './Footer';  
import logoImage from '../web2_images/YD_logo_light.png';
//import TeamOne from  './components/TeamOneDynamic'; 

import TeamOne from  './components/TeamOne'; 

const TeamLandingPage = () => {
    gsap.registerPlugin(ScrollTrigger);
    
    const [currentSection, setCurrentSection] = useState('section1');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const topLeftLogoRef = useRef(null);
    const containerRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };
    
    const handleLogoClick = () => {
        navigate("/#section1");
    };

    const topLeftLogoStyle = {
        position: 'fixed',
        top: '-1.1rem',
        left: '2rem',
        padding: '2rem',
        cursor: 'pointer',
        width: '15rem',
        zIndex: 100,
        opacity: 1,
        display: isMobile ? 'none' : 'block',
    };
      
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        const hash = location.hash.replace('#', '');
        if (hash) {
            const section = document.getElementById(hash);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
                setCurrentSection(hash);
            }
        }
    }, [location]);

    
    useGSAP(() => {
        const sections = document.querySelectorAll('section');

        sections.forEach((section) => {
            ScrollTrigger.create({
                trigger: section,
                start: "top 50%", // Adjust this value based on when you want to detect the section
                end: "bottom 90%", // onLeaveBack trigger point
                
                onEnter: () => {
                    setCurrentSection(section.id);
                },
                
                onEnterBack: () => {
                    setCurrentSection(section.id);
                },
            });
        });
    },  []);

    return (
        <div ref={containerRef}>
            {isMobile ? (
                <HamburgerMenu currentSection={"team"} activeSection={currentSection} />
            ) : (
                <Navbar current="team" activeSection={currentSection} />
            )}
            <TeamOne />
            <img src={logoImage} style={topLeftLogoStyle} ref={topLeftLogoRef} onClick={handleLogoClick} />
            <Footer /> 
        </div>
    );
};

export default TeamLandingPage;
