import React, { useState, useEffect } from 'react';
import facebookIcon from '../web2_images/socials/YD_SoMe_icons_fb-white.png';
import instagramIcon from '../web2_images/socials/YD_SoMe_icons_ig-white.png';
import linkedinIcon from '../web2_images/socials/YD_SoMe_icons_Li-white.png';
import youtubeIcon from '../web2_images/socials/YD_SoMe_icons_you-white.png';
import tiktokIcon from '../web2_images/socials/YD_SoMe_icons_tik-white.png';

const Footer = ({ initialModalOpen }) => {
  const [isModalOpen, setModalOpen] = useState(initialModalOpen || false);

  // Prevent body from scrolling when modal is open
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'unset'; // Enable scrolling
    }

    // Cleanup when component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  const footerStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    width: '100%',
    background: '#08424C',
    color: '#C7F5F9',
    fontFamily: 'Lato, sans-serif',
    padding: '20px 0',
    fontSize: '0.7em',
  };

  const iconStyle = {
    width: '30px',
    height: '30px',
    margin: '0 10px',
    opacity: '0.8',
    transition: 'opacity 0.3s ease-in-out',
  };

  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: isModalOpen ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  };

  const modalContentStyle = {
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '5px',
    width: '80%',
    fontFamily: 'Lato, sans-serif',
    maxWidth: '500px',
    zIndex: 4444,
    color: 'black',
    textAlign: 'left',
    maxHeight: '70vh', // Limits the modal height to 70% of the viewport
    overflowY: 'auto', // Makes content scrollable if it exceeds the modal height
  };

  const closeModal = (e) => {
    if (e.target.id === 'modal') {
      setModalOpen(false);
    }
  };
 // Prevent scroll events from propagating to the body
 const preventScrollPropagation = (e) => {
  e.stopPropagation();
};

  return (
    <div style={footerStyle}>
      <div style={{ textAlign: 'center', padding: '10px' }}>
        <div style={{ display: 'inline-block', paddingBottom: '15px' }}>
          <strong>Reach Out</strong>
        </div>
        <br />
        <a href="https://www.facebook.com/profile.php?id=61556798181929" target="_blank" rel="noopener noreferrer">
          <img src={facebookIcon} alt="Facebook" style={iconStyle} />
        </a>
        <a href="https://www.instagram.com/yourdevocean/" target="_blank" rel="noopener noreferrer">
          <img src={instagramIcon} alt="Instagram" style={iconStyle} />
        </a>
        <a href="https://www.linkedin.com/company/your-devocean/" target="_blank" rel="noopener noreferrer">
          <img src={linkedinIcon} alt="LinkedIn" style={iconStyle} />
        </a>
        <a href="https://www.youtube.com/@YourDevocean" target="_blank" rel="noopener noreferrer">
          <img src={youtubeIcon} alt="YouTube" style={iconStyle} />
        </a>
        <a href="https://www.tiktok.com/@yourdevocean" target="_blank" rel="noopener noreferrer">
          <img src={tiktokIcon} alt="TikTok" style={iconStyle} />
        </a>
        <br />
        <p>social@yourdevocean.com</p>
      </div>
      <div
        style={{ textAlign: 'center', padding: '10px', cursor: 'pointer' }}
        onClick={() => setModalOpen(true)}
      >
        <div style={{ display: 'inline-block', paddingBottom: '5px' }}>
          <strong>Privacy</strong>
        </div>
        <p style={{ cursor: 'pointer' }}>Privacy Policy</p>
      </div>
      <div style={{ textAlign: 'center', padding: '10px' }}>
        <div style={{ display: 'inline-block', paddingBottom: '5px' }}>
          <strong>Your Devocean AB</strong>
        </div>
        <p>
          Convendum, Vasagatan 16
          <br />
          4th floor, Stockholm, Sweden
        </p>
      </div>

      {/* Modal */}
      <div id="modal" style={modalStyle} onClick={closeModal}>
      <div
            style={modalContentStyle}
            onClick={(e) => e.stopPropagation()}
            onWheel={preventScrollPropagation}
            onTouchMove={preventScrollPropagation}
          >
          <h2>Privacy Policy</h2>
          <p>
            This Privacy Policy outlines the types of information Your Devocean collects, how we use and
            protect that information, and your choices regarding your personal data.
          </p>

          <h3>1. Information We Collect</h3>
          <p>
            When you click Sign up, you may chose to supply the following personal
            information:
          </p>
          <ul>
            <li>
              <strong>First Name</strong>
            </li>
            <li>
              <strong>Last Name</strong> 
            </li>
            <li>
              <strong>Email Address</strong> 
            </li>
            <li>City </li>
            <li>Country </li>
            <li>LinkedIn Profile URL </li>
          </ul>
          <p>
            This information is collected when you fill out a form on our website.
          </p>

          <h3>2. Use of Your Information</h3>
          <p>We use the personal information you provide for the following purposes:</p>
          <ul>
            <li>To communicate with you about our services, updates, and events</li>
            <li>To personalize your experience with our services</li>
            <li>With your explicit consent, in a display of public pledge to ocean stewardship</li>
            <li>
              To analyze and improve our services (only if you have provided consent for analytics
              tracking)
            </li>
          </ul>

          <h3>3. Data Storage and Security</h3>
          <p>
            Your personal information is stored on protected databases that are inaccessible to
            unauthorized personnel. Our servers are private and secure, ensuring that your data is
            safeguarded. We implement appropriate technical and organizational measures to protect your
            personal information against unauthorized access, alteration, disclosure, or destruction.
          </p>

          <h3>4. Cookies and Tracking Technologies</h3>
          <p>We use cookies to enhance your experience on our website:</p>
          <ul>
            <li>
              <strong>Consent Preference Cookie</strong>: If you decline the consent bar, we store a
              cookie on your device to remember your preference so that you are not prompted again.
            </li>
            <li>
              <strong>Google Analytics</strong>: We use Google Analytics to collect anonymized usage data
              to help us understand how our website is used and to improve our services.{' '}
              <strong>
                This tracking is only activated if you provide consent by accepting the consent form.
              </strong>{' '}
              If you decline, no Google Analytics tracking code is loaded, and no usage data is collected
              via Google Analytics.
            </li>
          </ul>
          <p>You can manage your cookie preferences through your browser settings.</p>

          <h3>5. Third-Party Data Collection</h3>
          <p>
            If you choose to participate in a survey, the survey will be powered by Typeform. The
            information you provide in these surveys is stored in Typeform’s databases. Your Devocean may
            access this information only if you explicitly consent during your participation in the
            survey. Please note that Typeform has its own privacy policies regarding the storage and
            handling of your data, which we do not control. We encourage you to review Typeform’s privacy
            policy.
          </p>

          <h3>6. Data Sharing and Disclosure</h3>
          <p>
            We do not sell, trade, or rent your personal information to third parties. We may share your
            information only in the following circumstances:
          </p>
          <ul>
            <li>With your explicit consent</li>
            <li>To comply with legal obligations or respond to lawful requests from public authorities</li>
          </ul>

          <h3>7. Your Rights and Choices</h3>
          <p>
            You have the right to access, correct, or delete your personal information held by us. To
            exercise these rights, please contact us at{' '}
            <a href="mailto:social@yourdevocean.com">social@yourdevocean.com</a>.
          </p>

          <h3>8. Changes to This Privacy Policy</h3>
          <p>
            We may update this Privacy Policy from time to time to reflect changes in our practices or for
            other operational, legal, or regulatory reasons. Any changes will be posted on this page with
            an updated revision date.
          </p>

          <h3>9. Contact Us</h3>
          <p>
            If you have any questions about this Privacy Policy or our data practices, please contact us
            at <a href="mailto:social@yourdevocean.com">social@yourdevocean.com</a>.
          </p>

          <p>
            Your Devocean
            <br />
            <i>10-02-2024</i>
          </p>
          <button onClick={() => setModalOpen(false)}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
