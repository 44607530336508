// LogoCarousel.jsx

import React from 'react';
import styled, { keyframes } from 'styled-components';

// Dynamically import all images from the directory
const importAll = (r) => r.keys().map(r);
const logos = importAll(require.context('../../web2_images/pledges/oceans', false, /\.(png|jpe?g|svg)$/));

// Keyframes for desktop animation
const scrollDesktop = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-25%);
  }
`;

// Keyframes for mobile animation
const scrollMobile = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-120%);
  }
`;

const CarouselContainer = styled.div`
  overflow: hidden;
  width: 100%;
  background-color: #08424C;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 300px; /* Adjust as needed */

  @media (max-width: 768px) {
    height: 200px; /* Adjust height for mobile */
  }
`;

const LogosWrapper = styled.div`
  display: flex;
  align-items: center;
  width: calc(200%);
  animation: ${scrollDesktop} 15s linear infinite alternate;

  @media (max-width: 768px) {
    width: calc(200%); /* Adjust width for mobile */
    animation: ${scrollMobile} 15s linear infinite alternate; /* Slower and alternate animation */
  }
`;

const LogosList = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const LogoItem = styled.img`
  height: 120px;
  margin: 0 60px;

  @media (max-width: 768px) {
    height: 80px; /* Adjust logo size for mobile */
    margin: 0 20px;
    flex-shrink: 0; /* Prevent logos from shrinking */
  }
`;

const TitleLine1 = styled.h2`
  color: #C7F5F9;
  margin-top: 50px;
  margin-bottom: -10px;
  font-size: 2.6rem;
  font-weight: 900;
  text-align: center;
  font-family: 'Lato', sans-serif;

  @media (max-width: 768px) {
    margin-top: 30px;
    font-size: 2rem;
  }
`;

const TitleLine2 = styled.h3`
  color: #C7F5F9;
  margin-bottom: 30px;
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;
  font-family: 'Lato', sans-serif;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const LogoCarousel = () => {
  return (
    <CarouselContainer>
      <TitleLine1>OUR PARTNERS</TitleLine1>
      <TitleLine2>for Ocean Regeneration</TitleLine2>

      <LogosWrapper>
        <LogosList>
          {logos.map((logo, index) => (
            <LogoItem src={logo} alt={`Logo ${index}`} key={index} />
          ))}
        </LogosList>
        <LogosList>
          {logos.map((logo, index) => (
            <LogoItem src={logo} alt={`Logo duplicate ${index}`} key={`duplicate-${index}`} />
          ))}
        </LogosList>
      </LogosWrapper>
    </CarouselContainer>
  );
};

export default LogoCarousel;
