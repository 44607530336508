import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import iceImage from '../../web2_images/landing_05.jpg'; // Adjust path as necessary
import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useLocation, useNavigate } from 'react-router-dom';
import SignupModalSeasteward from '../dynamic/signup/SignupModalSeasteward'; // Adjust path as necessary

gsap.registerPlugin(ScrollTrigger);

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // Align items at the top
  align-items: center;
  height: 48.49vh;  //  1122 ...  1638 reglar
  width: auto; // 2912
  background: url(${iceImage}) center 100% / cover no-repeat;

  text-align: center;
  color: #285C70;
  overflow:hidden; 
  z-index: 1;
  @media (max-width: 768px) {
    justify-content: center; // Center content vertically on mobile
    align-items: center; // Center content horizontally on mobile
  }
`;
const Section = styled.section`

`;

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;
  width: 50%;
  font-family: Lato, sans-serif;
  text-align: left;
  z-index: 5;
  align-items: start; // Ensure content starts at the same vertical position

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row; // Stack items vertically
    text-align: center;
    grid-gap: 0.4rem;
    padding: 0.4rem;
    margin-top: 0; // Remove top margin
    width: 100%;
    margin-left: 0; // Remove left margin
    justify-content: center; // Center items vertically
    align-items: center; // Center items horizontally
  }

  @media (min-width: 768px) {
    position: absolute;
    margin-top: 4.0rem;
  }
`;
const Button = styled.a`
  border: 4px solid #C7F5F9; /* Same turquoise color */
  background: transparent;
  color: #C7F5F9; /* Same turquoise color */
  padding: 0.1rem 0; /* Adjusted padding */
  margin: 0 0.2rem;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 900;
  cursor: pointer;
  border-radius: 0.5rem;
  width: 250px; /* Fixed width */
  display: inline-block; /* Ensures the element respects the width */

  &:hover {
    background: #87D2DB; /* Turquoise background on hover */
    color: white; /* White text on hover */
  }

  @media (max-width: 768px) {
    margin: 0.5rem 0;
    width:150px;
  }
`;


const BottomText = styled.p`
  margin: 0;
  padding: 1rem; // Adjusted padding
  font-size: 1rem;
  line-height: 1.5;
  height:6rem;

  font-family: Lato, sans-serif;
  color: #C7F5F9;
  text-align: center;
  width:80%;
  z-index: 5;

@media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 0.8rem;
    width:75%;
}
  
`;
const BottomBottomText = styled(BottomText)`
  font-weight: 900;
`

const LandingFive = () => {

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false); // Add state for modal visibility
    const impactButtonRef = useRef(null);
  
    const navigateTo = (page) => {
      navigate(page);
    }

    useGSAP(() => {
      
    });

    const openModal = () => {
      setIsModalOpen(true); // Open modal when button is clicked
    };
  
    const closeModal = () => {
      setIsModalOpen(false); // Close modal when necessary
    };
  return (
    <Section id="section5">
      <SectionContainer >
      <TextContainer>
          <div>
            <BottomBottomText>
              Join the waitlist to be notified at launch:
            </BottomBottomText>
            <BottomText>
              <Button ref={impactButtonRef} onClick={openModal}>SIGN UP</Button>
            </BottomText>
          </div>
          <div>
            <BottomBottomText>
              Come see how we can do this together:
            </BottomBottomText>
            <BottomText>
                <Button href="/offering#offering1" >SOLUTION</Button>
            </BottomText>
          </div>
        </TextContainer>
     </SectionContainer>
     <SignupModalSeasteward isOpen={isModalOpen} onClose={closeModal} />
   </Section>

  );
};

export default LandingFive;
