import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Navbar from './components/Navbar'; 
import HamburgerMenu from './components/HamburgerMenu'; 
import Footer from './Footer';  
import LandingThree from './components/LandingThree'; 
import LandingFour from './components/LandingFour'; 

import LandingFourFive from './components/LandingFourFive'; 
import LandingFive from './components/LandingFive'; 
import LandingOne from './components/LandingOne'; 
import SectionTwo from './components/SectionTwo'; 

import shorelineImage from '../landing_images/YD_webbg-01.jpg';
import logoImage from '../web2_images/yd_logo-small-tagline-light.png';
//import logoImage from '../images/blue_lives_matter.png';

import logoImage_dark from '../web2_images/yd_logo-small-tagline-og.png';
import logoImage_mobile from '../web2_images/YD_logo_og.png';

const LandingPageStatic = () => {
    gsap.registerPlugin(ScrollTrigger);
    
    const [currentSection, setCurrentSection] = useState('section1');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [currentLogo, setCurrentLogo] = useState(logoImage_dark);  // Start with the default logo
    const logoRef = useRef(null);
    const topLeftLogoRef = useRef(null);
    const containerRef = useRef(null);
    const location = useLocation();
    
    const queryParams = new URLSearchParams(location.search);
    const privacy = queryParams.get('privacy') === 'true';

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    const handleLogoClick = () => {
        var element = document.getElementById('section1');
        window.lenis.scrollTo(element);
    };

    const topLeftLogoStyle = {
        position: 'fixed',
        top: '-1.1rem',
        left: '2rem',
        padding: '2rem',
        cursor: 'pointer',
        width: '15rem',
        zIndex: 100,
        display: isMobile ? 'none' : 'block',
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const hash = location.hash.replace('#', '');
        if (hash) {
            const section = document.getElementById(hash);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
                setCurrentSection(hash);
            }
        }
    }, [location]);

    useGSAP(() => {
        const sections = document.querySelectorAll('section');

        sections.forEach((section) => {
            ScrollTrigger.create({
                trigger: section,
                start: "top 50%",
                end: "bottom 90%",
                
                onEnter: () => setCurrentSection(section.id),
                onEnterBack: () => setCurrentSection(section.id),
            });
        });
    }, []);

    useEffect(() => {
        const newLogoToShow = ["section2", "section3", "section4", "section5"].includes(currentSection) ? logoImage : logoImage_dark;
        if (newLogoToShow !== currentLogo) {
            gsap.to(topLeftLogoRef.current, {
                opacity: 0,
                duration: 0.5,
                onComplete: () => {
                    topLeftLogoRef.current.src = newLogoToShow;
                    setCurrentLogo(newLogoToShow);  // Update the current logo
                    gsap.to(topLeftLogoRef.current, { opacity: 1, duration: 0.5 });
                }
            });
        }
    }, [currentSection]);

    return (
        <div ref={containerRef}>
            {isMobile ? (
                <HamburgerMenu currentSection={"consciousConsumers"} activeSection={currentSection} />
            ) : (
                <Navbar current="consciousConsumers" activeSection={currentSection} />
            )}

            <img src={currentLogo} ref={topLeftLogoRef} style={topLeftLogoStyle} onClick={handleLogoClick} />

            <LandingOne 
                isMobile={isMobile} 
                logoImage={logoImage_mobile} 
                shorelineImage={shorelineImage} 
                setLogoRef={(ref) => logoRef.current = ref} 
            />
            
            <SectionTwo isMobile={isMobile} />
            <LandingThree isMobile={isMobile} />
            <LandingFour isMobile={isMobile} />
            <LandingFourFive isMobile={isMobile} />
            <LandingFive isMobile={isMobile} />
            <Footer initialModalOpen={privacy} />

        </div>
    );
};

export default LandingPageStatic;