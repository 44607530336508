// src/App.js

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPageStatic from './pages/LandingPageStatic';
import ConsciousConsumers from './pages/ConsciousConsumers';
import PartnerPage from './pages/PartnerLandingPage';
import SurveyPage from './pages/SurveyPage';
import CorporatePage from './pages/CorporatePage';
import ImpactLandingPage from './pages/ImpactLandingPage';
import TeamLandingPage from './pages/TeamLandingPage';
import SurveyLandingPage from './pages/SurveyLandingPage';
import SmoothScrolling from './pages/SmoothScrolling';
import CookieConsentComponent from './pages/components/CookieConsentComponent';
//import StewardWall from './pages/dynamic/signup/StewardWall';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga';

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;

const initializeGoogleAnalytics = () => {
  ReactGA.initialize(trackingId);
  ReactGA.pageview(window.location.pathname + window.location.search);
};


const App = () => {

  useEffect(() => {
    if (Cookies.get('cookieConsent') === 'accepted') {
      initializeGoogleAnalytics();
    }
  }, []);


  return (
    <SmoothScrolling>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPageStatic />} />
        <Route path="/org-survey" element={<SurveyPage />} />
        <Route path="/offering" element={<PartnerPage />} />
        <Route path="/impact" element={<ImpactLandingPage />} />
        <Route path="/team" element={<TeamLandingPage />} />
        <Route path="/surveys" element={<SurveyLandingPage />} />
      </Routes>
      <CookieConsentComponent />

    </Router>
    </SmoothScrolling>
  );
};

export default App;