import React, { useRef } from 'react';
import styled from 'styled-components';
import dolphinsImage from '../../compressed_images/landing_02_nero.jpg';

import dolphinsOverlayImage from '../../web2_images/landing_02-dolphlundgren.png';
import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Section = styled.section`
  height: 100vh;
  width: auto;
  font-family: Lato, sans-serif;
  background: url(${dolphinsImage}) top center / cover no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${dolphinsOverlayImage}) top center / cover no-repeat;
  z-index: 1;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;
  align-items: flex-start; /* Ensure alignment starts from the left */
  right: 5rem;
  bottom: 6rem;
  text-align:left;

  @media (max-width: 768px) {
    align-items: center;
    right: 0rem;
    text-align: center;

  }
`;

const BaseText = styled.p`
  font-family: Lato, sans-serif;
  color: white;
  position: relative;
  font-size: 1.5rem;
  margin-top: 10px;
  z-index: 0;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const Heading = styled.h2`
  letter-spacing: 0.01em;
  font-weight: 900;
  color: #ffffff;
  mix-blend-mode: soft-light;
  position: relative;
  font-size: 7rem;
  left: -10.2%;
  top: 10%;
  @media (max-width: 768px) {
    font-size: 6.2rem;
    left: 5%;
    top: 8%;
  }
`;

const SavingOceansText = styled(BaseText)`
  font-weight: 900;
  color: #87d2db;
  font-size: 1.2rem;
  top: 9rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    top: 10.5em;
  }
`;

const TrueLungsText = styled(BaseText)`
  
  letter-spacing: 0.1em;
  line-height: 3.2rem;
  font-weight: 600;
  text-align: left;
  top: 15%;
  margin-top: 7rem;
  opacity:0;


  @media (max-width: 768px) {
    font-size: 1.5rem;
    letter-spacing: 0.1em;
    text-align:center;
    padding:0.1rem;
    line-height: 2.4rem;
    top: 8%;
  }

  @media (min-width: 768px) {
    margin-bottom:0.2rem;
    font-size: 3.0rem;
  }
`;

const ProducesOxygenText = styled(BaseText)`
  margin-top: 30px;
  top: 10%;
  font-size: 2.0rem;
  line-height: 2.3rem;
  font-weight: 20;

  @media (max-width: 768px) {
    top: 2%;
    font-size: 1.1rem;
    text-align:left;
    line-height: 1.3rem;
    font-weight: 300;
  }
`;

const ComparisonText = styled(BaseText)`
  font-size: 1.1rem;
  font-weight: 300;
  top: 6%;

  @media (max-width: 768px) {
    margin-bottom: 8rem;
    font-size: 0.88rem;
    top: 2%;
  }
`;

const SectionTwo = ({ isMobile }) => {
  const oceanLife = useRef(null);
  const trueLungs = useRef(null);

  useGSAP(() => {
    gsap.fromTo(
      oceanLife.current,
      { y: '100%', opacity: 0 },
      {
        y: '0%',
        opacity: 1,
        scrollTrigger: {
          trigger: oceanLife.current,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      trueLungs.current,
      {opacity: 0, x:'-10%' },
      {
        opacity: 1,
        x:'0%',
        duration: 1.7,
        delay:0.4,
        scrollTrigger: {
          trigger: trueLungs.current,
          start: 'top bottom',
          end: 'bottom top',
        },
      }
    );

  }, []);

  return (
    <Section id="section2">
      <Overlay />
      <Heading ref={oceanLife}>OCEAN = LIFE</Heading>
      <TextContainer>
        <SavingOceansText>
          SOLVING OUR CLIMATE CRISIS <br /> REQUIRES SAVING OUR OCEANS
        </SavingOceansText>
        <TrueLungsText ref = {trueLungs}>
          TRUE LUNGS <span style={{ letterSpacing: '0.0em' }}> of our </span> EARTH
        </TrueLungsText>
        <ProducesOxygenText>
          Produces <span style={{ fontWeight: '600', fontSize: '1.2em' }}>~70% </span> of the oxygen we breathe
          <br /> Absorbs <span style={{ fontWeight: '600', fontSize: '1.2em' }}> ~30%</span> of man-made CO₂ emissions
        </ProducesOxygenText>
        <ComparisonText>
          In comparison, rainforests are responsible for <br /> ~20% of the oxygen and ~7% of carbon absorption
        </ComparisonText>
      </TextContainer>
    </Section>
  );
};

export default SectionTwo;
