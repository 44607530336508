import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import iceImage from '../../compressed_images/landing_03_nero.jpg'; // Adjust path as necessary
import { fadeInStyle, fadeInStyleOne, fadeInStyleEquals, fadeInStyleCars } from './globalStyles'; // Import the fade-in styles
import logoImage from '../../web2_images/YD_logo_symbol-turqbub.png';
import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // Align items at the top
  align-items: center;
  height: 68.49vh;  //  1122 ...  1638 reglar
  width: auto; // 2912
  background: url(${iceImage}) top center / cover no-repeat;
  text-align: center;
  color: #285C70;
  overflow:hidden; 
  z-index: 1;
  @media (max-width: 768px) {
    padding: 0 20px;
    padding-bottom:40px;
    height:auto;
  }
`;
const Section = styled.section`
`;


const TextContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  width: 10%;
  font-family: Lato, sans-serif;
  text-align: left;
  color: white;
  z-index: 5;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    grid-gap: 0rem;
    width: 100%;
    justify-items: center; // Center items horizontally
    margin-top: -1rem;
  }

  @media (min-width: 768px) {
    position:absolute;
    margin-right:50rem;
    margin-top: 16.5rem;
  }
`;

const TextContainer2 = styled.div`
    font-family: Lato, sans-serif;
    display:flex;
    align: center;
    flex-direction: column;
    font-size: 0.8rem;
    line-height: 0.3rem;
    z-index: 5;

    @media (min-width: 768px) {
      width: 30%;
      padding: 0 20px;
      text-align: left;
      margin-top: -1.35rem;
      margin-left: 35%;
    }
    @media (max-width: 768px) {
      width: 100%;
      align-items: center;
      margin-left: 0%;
      margin-top: 1.4rem;
    }
`;

const Text = styled.p`
  margin: 0;
  padding: 20px; // Adjusted padding
  line-height: 1.5;
  font-family: Lato, sans-serif;
  color: #C7F5F9;
  width:120%;
  font-weight: 700;
  z-index: 5;
  text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.8); // Added text shado


  @media (min-width: 768px) {
    font-size: 1.0rem;
    text-align: left;
  }
  
  @media (max-width: 768px) {
    font-size: 0.85rem;
    padding: 0.3rem;
    text-align: left;
    align-items: center;
    justify-content: center;
    width: 80%;
  }
`;
const TextItem = styled.div.attrs(props => ({
  'data-upside-down': props.upsideDown || undefined,
}))`
  position: relative;
  display: flex;
  justify-content: flex-start;
  font-size: 0.85rem;
  line-height: 1.5;
  color: white;
  padding: 10px;
  z-index: 5;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 30px solid #842252; // Pink triangle
    margin-right: 10px;
    z-index: -1;

    @media (max-width: 768px) {
      font-weight: bold;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #842252;
      margin-right: 0px;
    }

    ${props => props.upsideDown && css`
      transform: rotate(180deg); // Flip the triangle
    `}
  }

  @media (min-width: 768px) {
    font-size: 1.00rem;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 0.75rem;
    font-weight: bold;
    align-items: center;
  }
`;

const Title = styled.h2`
  font-size: 6rem;
  mix-blend-mode: soft-light;
  font-family: Lato, sans-serif;
  color: #FFFFFF;
  font-weight: 900;
  z-index: 5;


  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;
const HighlightText = styled.span`
  color: #C7F5F9;
  font-size: 2rem;
  font-weight: bold;
  z-index: 5;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Logo = styled.img`
width: 7%;
z-index: 5;
mix-blend-mode: soft-light;

@media (max-width: 768px) {
  display: none;
}

@media (min-width: 768px) {
  right:12rem;
  margin-left:-16rem;

  padding: 2.5rem;
}
`;


const BottomText = styled.p`
  margin: 0;
  padding: 20px; // Adjusted padding
  font-size: 0.8rem;
  line-height: 1.5;
  font-family: Lato, sans-serif;
  color: #C7F5F9;
  width:120%;
  z-index: 5;

  @media (min-width: 768px) {
    font-size: 1.15rem;
    text-align: left;
    margin-top:-1rem;
  }
  
  @media (max-width: 768px) {
    font-size: 0.95rem;
    padding: 0.0rem;
    text-align: left;
    margin-top:4.5rem;
    bottom: 12rem;
    width: 70%;
    align-items: center;
    justify-content: center;
  }
`;


const BottomText2 = styled.p`
  margin: 0;
  padding: 20px; // Adjusted padding
  font-ize: 0.9rem;
  line-height: 1.5;
  font-family: Lato, sans-serif;
  color: #C7F5F9;
  width:120%;
  font-weight: 300;
  z-index: 5;

  @media (min-width: 768px) {
    font-size: 1.05rem;
    text-align: left;
    margin-top:-2rem;
  }
  
  @media (max-width: 768px) {
    font-size: 0.85rem;
    padding: 0.0rem;
    text-align: left;
    width: 70%;
    align-items: center;
    margin-top: 1rem;
    justify-content: center;
  }
`;

const BottomContainer = styled.div`
  position: relative;
  width: 100%;
  height: 31.51vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #08424C;
  color: #C7F5F9;
  font-family: 'Lato', sans-serif;
  overflow-y:hidden;
  z-index: 5;

  @media (max-width: 768px) {
    height: 40.00vh;
    justify-content: center;
    flex-direction: column; // Adjust to column for better alignment on mobile

  }
`;

const Unbalanced = styled.div`
  width: 40%;
  z-index: 5;

  @media (max-width: 768px) {
    display: flex; // Add this line
    flex-direction: column; // Add this line
    justify-content: center; // Add this line
    align-items: center; // Add this line
    width: 100%;
    margin-top: -5.0rem;
    justify-content: center
    text-align: center; // Center text on mobile
  }
`

const WhiteBox = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  mix-blend-mode: hue;
  opacity: 0;
  z-index: 2;
`;

const LandingThree = () => {
  const whiteBoxRef = useRef(null);
  const dyingRef = useRef(null);
  const oceanLife = useRef(null);
  const currentAnimRef = useRef(null);

  useGSAP(() => {
    const timeline = gsap.timeline({ delay: 3 });
  
    // Select all TextItem elements
    const textItems = gsap.utils.toArray('.text-item');
    
    // Shuffle the array for randomized order
    gsap.utils.shuffle(textItems);
  
    textItems.forEach((item) => {
      const isUpsideDown = item.dataset.upsideDown !== undefined;
    
      // Calculate random start time within the first 2 seconds
      const randomStartTime = Math.random() * 2;
    
      // Create a new timeline for each item with the randomized start time
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: item,
          start: 'top 80%',  // Start the animation when the top of the element reaches 80% of the viewport height
          end: 'top 20%',    // End/reverse the animation when the top of the element reaches 20% of the viewport height
          toggleActions: 'play reverse play reverse', // Controls the animation on enter and leave
        }
      });
    
      // Add the animation to the timeline with the random start time
      timeline.fromTo(
        item,
        { y: isUpsideDown ? -50 : 50, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 2.5,
          ease: 'power2.out',
          delay: randomStartTime, // Apply the random start time
        }
      );
    });
  
    // ScrollTrigger to start the main animation when oceanLife.current comes into view
    ScrollTrigger.create({
      trigger: oceanLife.current,
      start: 'bottom bottom',
      end: 'top top',
      onEnter: () => timeline.play(),
      onLeaveBack: () => timeline.reverse(),
    });
  
    const onEnter = () => {
      if (currentAnimRef.current) currentAnimRef.current.kill();
      currentAnimRef.current = gsap.to(whiteBoxRef.current, {
        opacity: 0.76,
        duration: 14,
        onComplete: () => (currentAnimRef.current = null),
      });
    };
  
    const onLeaveBack = () => {
      if (currentAnimRef.current) currentAnimRef.current.kill();
      currentAnimRef.current = gsap.to(whiteBoxRef.current, {
        opacity: 0,
        duration: 1,
        onComplete: () => (currentAnimRef.current = null),
      });
    };
  
    ScrollTrigger.create({
      trigger: oceanLife.current,
      start: 'top bottom',
      end: 'top bottom',
      onEnter,
      onLeaveBack,
    });
  
    gsap.fromTo(
      oceanLife.current,
      { y: '100%', opacity: 0 },
      {
        y: '0%',
        opacity: 1,
        scrollTrigger: {
          trigger: oceanLife.current,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true
        },
      }
    );
  });
  
  return (
    <Section id="section3">
      <SectionContainer >
        <WhiteBox ref={whiteBoxRef} />
            <Title ref={oceanLife}>BUT OCEANS ARE DYING</Title>
        <TextContainer>
          <TextItem className="text-item" upsideDown>BIODIVERSITY</TextItem>
          <TextItem className="text-item">TEMPERATURE</TextItem>
          <TextItem className="text-item" upsideDown>CO₂ CAPTURE</TextItem>
          <TextItem className="text-item">POLLUTION</TextItem>
          <TextItem className="text-item" upsideDown>O₂ CREATION</TextItem>
          <TextItem className="text-item">ACIDIFICATION</TextItem>
        </TextContainer>
        <TextContainer2>
          <Text ref ={dyingRef}>Four billion years ago, mother ocean gave birth to multicellular organisms and bacteria, that created our atmosphere, enabling life to thrive not only in water, but also on land.</Text>
          <Text >Now, the life in the Ocean is dying, our atmosphere slowly deteriorating,<br/> <HighlightText>and with it the oxygen we breathe.</HighlightText></Text>
        </TextContainer2>
    </SectionContainer>
        <BottomContainer>
            <Logo src={logoImage} alt="logo" />
            <Unbalanced>
              <BottomText>Industrial CO₂ emissions is the cause of ocean acidification, which in turn degrades biodiversity. Reducing CO₂ emissions is essential to preserve life. </BottomText>
              <BottomText2 >
                People experience the consequences every day in terms of human health, local economies, clean water access, natural disasters, migratory displacement & more.
              </BottomText2>
            </Unbalanced>
        </BottomContainer>    
   </Section>

  );
};

export default LandingThree;
